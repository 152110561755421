<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader">
            <div class="mt-3 mb-4 cardStyle p-4 recommended-readings-form">
                <div class="row content mt-2">
                    <div class="col-md-6 col-sm-12">
                        <base-input name="name" label="Name*" v-model="form.name" :rules="{ custom_required: true }"
                            placeholder="Name">
                        </base-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <base-select name="status" label="Status*" v-model="form.status" :options="statusOptions"
                            :rules="{ custom_required: true }">
                        </base-select>
                    </div>
                    <div class="col-12 col-sm-12">
                        <validation-provider :rules="{ custom_required: true }" name="description" v-slot="{errors, valid, invalid, validated}">
                            <div class="form-group">
                                <label class="form-control-label">Description*</label>
                                <textarea v-model="form.description" class="form-control" rows="7" :class="[{'is-invalid': invalid && validated}]"></textarea>
                                <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </validation-provider>
                    </div>
                </div>
            </div>
            <div>
                <base-button native-type="submit" type="dark-blue" size="xl" class="mb-5" :disabled="loader.submit">{{
                    `${!recommendedReading ? 'Save' : 'Update'}` }}</base-button>
            </div>
        </form>
    </validation-observer>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: ['recommendedReading','loader'],
    components: {
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            form: {
                name: '',
                description: '',
                status: '',
            },
            statusOptions: [
                {
                    label: 'Active',
                    value: 1,
                },
                {
                    label: 'Inactive',
                    value: 0,
                }
            ],
        };
    },
    mounted() {
        if (this.recommendedReading) {
            this.form.status = this.recommendedReading?.status || '';
            this.form.name = this.recommendedReading?.name || '';
            this.form.description = this.recommendedReading?.description || '';
        }
    },
    watch: {
        'recommendedReading': function (newValue) {
            this.form.status = newValue.status;
            this.form.name = newValue.name;
            this.form.description = newValue.description;
        }
    },
    methods: {
        async onSubmit() {
            this.$emit('submit',this.form);
            let validation = this.$refs.formValidator;

        }
    },
};
</script>
  
<style lang="scss">
.recommended-readings-form{
    input{
        &.form-control {
        height: calc(1.5em + 1.25rem + 2px);
    }
    }
}
</style>
  